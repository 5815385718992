.university_block{
    font-size: 1.5rem;
    padding: 0 .5rem;
}
.university_degree{
    font-size: 1.25rem;
    font-weight: 600;
}
.university_school{
    font-size: 1.25rem;
    padding: 0 .5rem;
    font-weight: 400;
}
.university_time{
    font-size: 1rem;
    padding: 0 .5rem;
    font-weight: 100;
}