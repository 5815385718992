.section{
    display: block;
    margin:0 auto;
    width:100%;
    background:cyan;
}

.container{
    display: block;
    margin:0 auto;
    width:100%;
    max-width: 1200px;
    color:black;
    padding: .5rem .25rem;
}
.title{
    font-size: 2.5rem;
    margin: 0 0 1rem;
}
.description{
    font-size: 1rem;
    margin: 0 0 1rem;
}

.fence{
    margin: 50px;
    border: 1px solid brown;
    padding:2px;
    height:500px;
    position:relative;
}


.apple{
    display: block;
    width:25px;
    position: absolute;
    left: attr(positionX);
    top: attr(positionY);
}
.leaf{
    display:block;
    width:5px;
    height:5px;
    margin:0 auto;
    background-color:green
}
.flesh{
    display:block;
    width:100%;
    height:25px;
    margin:0 auto;
    background-color:red
}
