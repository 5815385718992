nav{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
}

nav a{
    padding:2rem .5rem;
    font-size: 1.25rem;
    text-decoration: none;
    color:#000;
}
nav a:hover{
    background:#000;
    color:#f0f0f0;
}
