.splash{
    display: block;
    position: relative;
    height:calc(100vh - 84px);
    background:#000;
}

.splash-card{
    position: absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    border: 1px solid #fff;
}


.splash-border{
    margin: 10px 10px;
    padding: 20px 50px;
    border: 3px solid #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.splash-card h1{
    color:#fff;
    font-size: 2rem;
    text-align: center;
    margin: 0 0 .5rem;
}
.splash-card h2{
    color:#fff;
    font-size: 1.5rem;
    text-align: center;
}

.splash-card span{
    color:#fff;
    line-height: .75;
    font-size: 100px;
    text-align: center;
    display: inline;
}