.experience{
    color:#000;
    padding: 0 12px;
    margin: 2rem 0;
}
.experience_title{
    font-size:2.5em;
    margin: 0 0 1rem 0;
}
.job_padding{
    width: 50vw;
    margin: 1rem auto;
    text-align: center;
}
.job_line{
    width:100%;
    border-bottom: 1px dotted black;
}