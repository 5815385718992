.skill_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 .5rem;
    padding: .1rem;
    border-bottom: 1px black solid;
    border-radius: 3;
}
.skill_block_title{
    font-size: 1.25rem;
    margin: 0 2rem 0 0;
}
.skill_block_experience{
    font-size: 1rem;
}