.skills{
    color:#000;
    padding: 0 12px;
    margin: 2rem 0;
}
.skills_title{
    font-size: 2.5rem;
    margin: 0 0 1rem;
}
.skills_container{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: row;
}
.skills_list{
    margin: 0 1rem;
    width:33%
}
.skills_list_title{
    font-size: 1.5rem;
    margin: 0 0 .5rem;
}

@media (max-width: 768px) {
    .skills_container {
        flex-direction: column;
        justify-content: center;
    }
    .skills_list{
        margin: 0 auto;
        width:95%;
    }
  }