.section{
    display: block;
    margin:0 auto;
    width:100%;
    background:black;
}

.container{
    display: block;
    margin:0 auto;
    width:100%;
    max-width: 1200px;
    color:white;
    padding: .5rem .25rem;
}
.title{
    font-size: 2.5rem;
    margin: 0 0 1rem;
}
.description{
    font-size: 1rem;
    margin: 0 0 1rem;
}

