footer{
    padding: 1rem 2rem;
    color: #fff;
    background-color: #000;
}
footer .container{
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
}
footer a{
    display:block;
    margin: 0 0 1rem;
    color:#fff;
    cursor: pointer;
}